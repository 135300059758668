import { useMemo } from 'react'

import { useAuth } from '@clerk/nextjs'
import useSWR, { SWRConfiguration } from 'swr'
import { compare } from 'swr/_internal'

import { DEFAULT_REQUEST_REVALIDATE_INTERVAL } from 'constants/requests'
import { getHttpService, GetHttpServiceReturn } from 'utils/http'

type ServiceType = (params: any) => ReturnType<typeof getHttpService>

type DefaultParams<Service extends ServiceType> = Omit<
  SWRConfiguration<Awaited<ReturnType<ReturnType<Service>['fetcher']>>>,
  'compare'
>

export type Params<Service extends ServiceType> = DefaultParams<Service> | void

export type InternalParams<Return = any> = SWRConfiguration<Return>

type UseRequestParams<Return> = {
  service: GetHttpServiceReturn<Return>
  requireAuth?: boolean
} & Omit<InternalParams<any>, 'compare'>

export const useRequest = <Return>({
  service,
  requireAuth,
  ...swrOptions
}: UseRequestParams<Return>) => {
  const { getToken, isSignedIn, userId } = useAuth()

  const key = useMemo(() => {
    if (typeof swrOptions?.isPaused === 'function') {
      if (swrOptions.isPaused()) {
        return null
      }
    }

    return requireAuth ? [service.key, isSignedIn, userId] : [service.key]
  }, [isSignedIn, requireAuth, service.key, swrOptions, userId])

  const { data, isLoading, mutate } = useSWR(
    key,
    async () => {
      if (requireAuth === false) {
        return service.fetcher({ token: null })
      }

      const token = await getToken()

      if (!token) {
        return undefined
      }

      return service.fetcher({ token })
    },
    {
      refreshInterval: DEFAULT_REQUEST_REVALIDATE_INTERVAL,
      ...swrOptions,
      compare: (a: any, b: any) => {
        return compare(a?.data, b?.data)
      },
      isPaused: () => false,
    }
  )

  return { response: data, isLoading, mutate }
}
