import { Company, CompanyOpeningHour, CompanyPlusIncludes } from 'models/companies'

import { api as internalApi } from 'providers/clientSide/internal'
import { api } from 'providers/clientSide/nexpy'

import {
  createAuthHttpService,
  getApiAuthPath,
  getHttpService,
  withAuthHeader,
} from 'utils/http'

export const getServiceCompanyById = (companyId: string) =>
  getHttpService<Company>({
    fetcher: api.get,
    path: `/companies/${companyId}`,
  })

export const getServiceCompanyByUsername = (username: string) =>
  getHttpService<Company>({
    fetcher: api.get,
    path: `/companies/${username}/by-username`,
  })

export const getServiceCompaniesByCurrentUser = () =>
  createAuthHttpService<CompanyPlusIncludes[]>({
    fetcher: api.get,
    path: '/companies/by-user',
  })

export const service_TestCompanyNameCreation = (
  requestData: { name: string },
  token: string | null
) =>
  api.post<void>(getApiAuthPath('/companies-name'), requestData, {
    ...withAuthHeader(token),
  })

export const service_CreateCompany = (
  requestData: { name: string },
  token: string | null
) =>
  api.post<Company>(getApiAuthPath('/companies'), requestData, {
    ...withAuthHeader(token),
  })

export const service_EditCompany = (
  companyId: string,
  requestData: {
    name?: string
    username?: string
    isActive?: boolean
  },
  token: string | null
) =>
  api.patch<Company>(getApiAuthPath(`/companies/${companyId}`), requestData, {
    ...withAuthHeader(token),
  })

export const service_SetCompanyDetails = (
  companyId: string,
  requestData: {
    phone?: string
    description?: string
    city?: string
    state?: string
    adressLine1?: string
    adressLine2?: string
  },
  token: string | null
) =>
  api.patch<Company>(getApiAuthPath(`/companies/${companyId}/set-details`), requestData, {
    ...withAuthHeader(token),
  })

export const service_SetCompanyCategories = (
  companyId: string,
  requestData: {
    companyCategoryIds: string[]
    primaryCompanyCategoryId: string
  },
  token: string | null
) =>
  api.patch<void>(getApiAuthPath(`/companies/${companyId}/set-categories`), requestData, {
    ...withAuthHeader(token),
  })

export const service_SendLogoImage = (
  companyId: string,
  data: FormData,
  token: string | null
) =>
  api.post(
    getApiAuthPath(`/companies/${companyId}/logo-image?alt=Logo%20da%20empresa`),
    data,
    {
      ...withAuthHeader(token, {
        'Content-Type': 'multipart/form-data',
      }),
    }
  )

export const service_SendBannerImage = (
  companyId: string,
  data: FormData,
  token: string | null
) =>
  api.post(
    getApiAuthPath(`/companies/${companyId}/banner-image?alt=Banner%20da%20empresa`),
    data,
    {
      ...withAuthHeader(token, {
        'Content-Type': 'multipart/form-data',
      }),
    }
  )

export const service_RemoveBannerImage = (companyId: string, token: string | null) =>
  api.delete(getApiAuthPath(`/companies/${companyId}/banner-image`), {
    ...withAuthHeader(token),
  })

export const service_SetCompanyHours = (
  companyId: string,
  requestData: {
    openingHours: Pick<
      CompanyOpeningHour,
      'dayOfWeek' | 'dayShift' | 'startTime' | 'endTime'
    >[]
  },
  token: string | null
) =>
  api.post<void>(
    getApiAuthPath(`/companies/${companyId}/set-opening-hours`),
    requestData,
    {
      ...withAuthHeader(token),
    }
  )

export const getServiceCompanyOpenHours = ({ companyId }: { companyId: string }) =>
  getHttpService<CompanyOpeningHour[]>({
    fetcher: api.get,
    path: `/companies/${companyId}/get-opening-hours`,
  })

export const getServiceCompanyOpenHoursByUsername = ({
  username,
}: {
  username: string
}) =>
  getHttpService<CompanyOpeningHour[]>({
    fetcher: api.get,
    path: `/companies/${username}/get-opening-hours/by-username`,
  })

export const service_SearchCompany = (query: string) =>
  api.get<Company[]>('/companies-search', {
    params: {
      q: query,
    },
  })

export const service_GetCompanyByQrId = (c: string) =>
  api.get<Company>(`/companies-qrid/${c}`)

export const service_CompanyCreationNotice = (requestData: {
  companyId: string
  internalUserId: string
  integrationId: string
}) => internalApi.post<void>('/company-creation-notice', requestData)
