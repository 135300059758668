import { NotificationModel } from 'models/notifications'

import { api } from 'providers/clientSide/nexpy'

import { createAuthHttpService, getApiAuthPath, withAuthHeader } from 'utils/http'

export const getServiceNotifications = () =>
  createAuthHttpService<NotificationModel[]>({
    fetcher: api.get,
    path: `/notifications/list-by-user`,
  })

export const service_DeleteNotification = (
  notificationId: string,
  token: string | null
) =>
  api.delete<NotificationModel>(
    getApiAuthPath(`/notifications/clear/${notificationId}`),
    {
      ...withAuthHeader(token),
    }
  )

export const service_DeleteAllNotifications = (token: string | null) =>
  api.delete<NotificationModel>(getApiAuthPath(`/notifications/clear-all`), {
    ...withAuthHeader(token),
  })

export const service_SetNotificationViewed = (
  notificationId: string,
  token: string | null
) =>
  api.patch<NotificationModel>(
    getApiAuthPath(`/notifications/set-viewed/${notificationId}`),
    {},
    {
      ...withAuthHeader(token),
    }
  )
