export const PwaRoutes = {
  PREPARE: '/pwa',
  INSTALL: '/install',
  IOS_INSTALL: '/install-ios',
  RECOVER_INSTALL: '/recover-install',
}

export const _Public_PwaRoutes = [
  PwaRoutes.PREPARE,
  PwaRoutes.INSTALL,
  PwaRoutes.IOS_INSTALL,
  PwaRoutes.RECOVER_INSTALL,
]
