import { useState, Dispatch, SetStateAction, ReactNode, useMemo } from 'react'

import { createContext } from '@nexpy/react-easy-context-api'

type AccountManageMenuContextValue = {
  isOpened: boolean
  setIsOpened: Dispatch<SetStateAction<boolean>>
}

export const AccountManageMenuContext = createContext<AccountManageMenuContextValue>(
  {} as AccountManageMenuContextValue
)

type MenuProviderProps = {
  children: ReactNode
}

export const AccountManageMenuProvider = ({ children }: MenuProviderProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const value = useMemo(() => ({ isOpened, setIsOpened }), [isOpened])

  return (
    <AccountManageMenuContext.Provider value={value}>
      {children}
    </AccountManageMenuContext.Provider>
  )
}
