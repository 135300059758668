import { useRouter } from 'next/router'
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react'

import { createContext } from '@nexpy/react-easy-context-api'
import ms from 'ms'
import { useDebounce } from 'usehooks-ts'

type LoaderContextValue = {
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export const LoaderContext = createContext<LoaderContextValue>({} as LoaderContextValue)

type LoaderProviderProps = {
  children: ReactNode
}

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isRouteChanging, setIsRouteChanging] = useState(false)

  const debouncedIsRouteChanging = useDebounce(isRouteChanging, ms('1.8s'))

  const value = useMemo(
    () => ({
      isLoading: isLoading || isRouteChanging ? debouncedIsRouteChanging : false,
      setIsLoading,
    }),
    [debouncedIsRouteChanging, isLoading, isRouteChanging]
  )

  const router = useRouter()

  useEffect(() => {
    const routeChangeStart = () => {
      setIsRouteChanging(true)
    }

    const routeChangeComplete = () => {
      setIsRouteChanging(false)
    }

    const routeChangeError = () => {
      setIsRouteChanging(false)
    }

    router.events.on('routeChangeStart', routeChangeStart)
    router.events.on('routeChangeComplete', routeChangeComplete)
    router.events.on('routeChangeError', routeChangeError)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeComplete)
      router.events.off('routeChangeError', routeChangeError)
    }
  }, [router.events])

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
}
