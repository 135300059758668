import { MutableRefObject, ReactNode, useMemo, useRef } from 'react'

import { createContext } from '@nexpy/react-easy-context-api'

type MainRefContextContextValue = {
  mainRef: MutableRefObject<HTMLDivElement | null>
}

export const MainRefContextContext = createContext<MainRefContextContextValue>(
  {} as MainRefContextContextValue
)

type MainRefContextProviderProps = {
  children: ReactNode
}

export const MainRefContextProvider = ({ children }: MainRefContextProviderProps) => {
  const mainRef = useRef<HTMLDivElement | null>(null)

  const value = useMemo(() => ({ mainRef }), [mainRef])

  return (
    <MainRefContextContext.Provider value={value}>
      {children}
    </MainRefContextContext.Provider>
  )
}
