/* eslint-disable no-empty */
export const getWindow = () => {
  try {
    if (typeof window !== 'undefined') {
      return window
    }
  } catch {}

  return null
}
