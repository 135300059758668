import { useRequest, Params } from 'hooks/useRequest'
import ms from 'ms'

import { getServiceCompaniesByCurrentUser } from 'services/clientSide/http/companies'

export const useCurrentUserCompanies = (
  params: Params<typeof getServiceCompaniesByCurrentUser>
) => {
  const response = useRequest({
    service: getServiceCompaniesByCurrentUser(),
    requireAuth: true,
    refreshInterval: () => ms('60s'),
    ...params,
  })

  return response
}
