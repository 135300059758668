import { useRequest, Params } from 'hooks/useRequest'

import { getServiceNotifications } from 'services/clientSide/http/notifications'

export const useNotifications = (params: Params<typeof getServiceNotifications>) => {
  const response = useRequest({
    service: getServiceNotifications(),
    requireAuth: true,
    ...params,
  })

  return response
}
