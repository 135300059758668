import dayjs from 'dayjs'
import isNumber from 'is-number'
import slugifyCore from 'slugify'

type DateFormatterMode = 'default'

export const getFullName = ({
  firstName,
  lastName,
  returnIfNull,
}: {
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  returnIfNull?: string | undefined
}) =>
  `${firstName || ''} ${
    firstName ? lastName || '' : `${returnIfNull || 'Nome ausente'}`
  }`.trim()

export const dateFormatter = (
  dayjsInstance: typeof dayjs,
  date: string,
  mode?: DateFormatterMode
) => {
  switch (mode) {
    case 'default':
      return dayjsInstance(date).format('DD/MM/YYYY')

    default:
      return dayjsInstance(date).format('DD/MM/YYYY')
  }
}

export const slugify = (value: string) => {
  return slugifyCore(value, { lower: true })
}

export const safeConvertToNumber = <Return>(toConvert: unknown, returnValue: Return) => {
  return isNumber(toConvert) ? Number(toConvert) : returnValue
}

export const getCurrency = (value: number, fractionDigits?: number) =>
  value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: fractionDigits,
  })

export const getColumnsByLength = (length: number, limit: number) => {
  if (length > limit) return new Array(limit).fill('1fr').join(' ')

  if (length === 0) return '1fr'

  return new Array(length).fill('1fr').join(' ')
}

export const onlyNumbers = (str: string) => {
  const regex = /\d+/g
  const numeros = str.match(regex)

  if (numeros && numeros.length > 0) {
    return numeros.join('')
  }

  return ''
}

export const truncateString = (input: string, maxLength: number, suffix = '...') => {
  if (typeof input !== 'string') {
    return '-'
  }

  if (input.length <= maxLength) {
    return input.trim()
  }

  return `${input.substring(0, maxLength).trim()}${suffix}`
}
