import { createGlobalStyle } from '@xstyled/styled-components'

const ClerkStyle = createGlobalStyle`
  .cl-pageScrollBox {
    overflow: unset;
  }

  .cl-formFieldLabel {
    font-weight: 400;
  }

  .cl-socialButtonsIconButton {
    min-height: 4rem;
  }

  .cl-socialButtonsProviderIcon {
    width: 2rem;
  }

  .cl-logoImage, .cl-logoBox {
    height: 8rem;
  }

  .cl-logoImage {
    width: 8rem;
  }

  .cl-logoBox {
    width: 100%;
    justify-content: center;
  }

  .cl-providerIcon {
    width: 3rem;
  }

  .cl-profileSection .cl-providerIcon {
    width: 1.8rem;
  }

  .cl-accordionTriggerButton > div > span > div {
    justify-content: flex-start;
  }

  .cl-signUp-root > .cl-card,
  .cl-signIn-root > .cl-card {
    width: 65rem;
    max-width: 80vw;
  }

  .cl-rootBox {
    box-shadow: none !important;
    --x-shadow: rgba(0,0,0,0) 0px 24px 48px !important;
  }

  .cl-userButtonPopoverCard {
    width: fit-content;
    min-width: 28rem;
  }

  .cl-userProfile-root > .cl-card {
    width: 90rem;
    max-width: 80vw;
  }

  .cl-modalContent {
    height: min(60rem, 100% - 3rem);
  }

  .cl-navbarMobileMenuButton svg {
    transform: scale(2.2);
    margin-right: 1.2rem;
  }

  .cl-navbarButtonIcon {
    transform: scale(1.6);
    margin-right: 0.4rem;
  }

  .cl-modalCloseButton {
    transform: scale(1.8);
    margin-right: 0.6rem;
  }

  .cl-navbarMobileMenuButton {
    font-size: 1.8rem;
  }

  .cl-formFieldInputShowPasswordButton svg {
    transform: scale(1.6);
  }

  .cl-userButtonPopoverActionButtonIconBox svg {
    transform: scale(2);
  }

  .cl-userButtonAvatarBox {
    width: 4.2rem;
    height: 4.2rem;
  }

  .cl-breadcrumbsItemDivider {
    display: flex;
    align-items: center;
  }

  .cl-breadcrumbsItem {
    display: flex;
    align-items: center;
  }

  /* TODO: Remove before add TikTok and Facebook Auth */
  .cl-socialButtonsBlockButton {
    border: none;
    gap: 2rem;
  }

  #apple img {
    width: 1.8rem;
  }

  #google img {
    width: 1.8rem;
  }

  #facebook img {
    width: 1.8rem;
  }

  #apple {
    max-width: 45rem;
  }

  #google {
    max-width: 45rem;
  }

  #facebook {
    max-width: 45rem;
  }

  .cl-internal-b3fm6y,
  .cl-userButtonPopoverFooter {
    display: none;
  }
`

export default ClerkStyle
