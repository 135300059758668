import { ReactNode, useEffect, useMemo, useState } from 'react'

import { createContext } from '@nexpy/react-easy-context-api'

type ClientSideContextValue = {
  isClientSide: boolean
}

export const ClientSideContext = createContext<ClientSideContextValue>(
  {} as ClientSideContextValue
)

type ClientSideProviderProps = {
  children: ReactNode
}

export const ClientSideProvider = ({ children }: ClientSideProviderProps) => {
  const [isClientSide, setIsClientSide] = useState(false)

  useEffect(() => {
    setIsClientSide(true)
  }, [])

  const value = useMemo(() => ({ isClientSide }), [isClientSide])

  return <ClientSideContext.Provider value={value}>{children}</ClientSideContext.Provider>
}
