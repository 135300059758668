import { ReactNode } from 'react'

import { ThemeProvider } from '@nexpy/design-system'
import { SWRConfig } from 'swr'

import { AccountManageMenuProvider } from 'contexts/AccountManageMenuContext'
import { ClientSideProvider } from 'contexts/ClientSideContext'
import { CompanyProvider } from 'contexts/CompanyContext'
import { ConsoleSettingsMenuProvider } from 'contexts/ConsoleSettingsMenuContext'
import { InternalUserProvider } from 'contexts/InternalUserContext'
import { LoaderProvider } from 'contexts/LoaderContext'
import { MainRefContextProvider } from 'contexts/MainRefContext'
import { OneSignalProvider } from 'contexts/OneSignalContext'

import { swrCacheProvider } from 'utils/cache'

import { customTheme } from 'theme/theme'

type AppProviderProps = {
  children: ReactNode
}

const AppProvider = ({ children }: AppProviderProps) => (
  <SWRConfig value={{ provider: swrCacheProvider }}>
    <InternalUserProvider>
      <LoaderProvider>
        <ThemeProvider
          theme={customTheme}
          defaultStyleMode='minimalist'
          renderWithAdaptativeRootRemRefCSS={`
                :root {
                  font-size: 50%;
                }

                @media (min-width: 1536px) {
                  :root {
                    font-size: 62.5%;
                  }
                }
              `}
        >
          <ClientSideProvider>
            <AccountManageMenuProvider>
              <ConsoleSettingsMenuProvider>
                <CompanyProvider>
                  <MainRefContextProvider>
                    <OneSignalProvider>{children}</OneSignalProvider>
                  </MainRefContextProvider>
                </CompanyProvider>
              </ConsoleSettingsMenuProvider>
            </AccountManageMenuProvider>
          </ClientSideProvider>
        </ThemeProvider>
      </LoaderProvider>
    </InternalUserProvider>
  </SWRConfig>
)

export default AppProvider
