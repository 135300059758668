import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react'
import OneSignal from 'react-onesignal'

import { Button, Flex, Modal, Text } from '@nexpy/design-system'
import { createContext } from '@nexpy/react-easy-context-api'
import {
  NEXT_PUBLIC_ONE_SIGNAL_APP_ID,
  NEXT_PUBLIC_ONE_SIGNAL_ENABLED,
  NEXT_PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID,
} from 'environment'
import { useClientSide } from 'hooks/useClientSide'
import { AccountManageRoutes } from 'routes/account-manage'

import Notify from 'components/images/Notify'

import { useNotifications } from 'services/clientSide/hooks/useNotifications'

import { META_TAGS_IDENTIFIER_KEYS } from 'constants/meta'
import { getWindow } from 'utils/globals'

import { InternalUserContext } from './InternalUserContext'

type HeadManagerRootProps = {
  hasSomeNotificationUnviewed: boolean
  currentNotificationTitle: string
}

const HeadManagerRoot = ({
  hasSomeNotificationUnviewed,
  currentNotificationTitle,
}: HeadManagerRootProps) => {
  const router = useRouter()

  return (
    <>
      {hasSomeNotificationUnviewed ? (
        <Head key={router.pathname}>
          <title key={META_TAGS_IDENTIFIER_KEYS.TITLE}>{currentNotificationTitle}</title>
          <link
            key={META_TAGS_IDENTIFIER_KEYS.FAVICON}
            rel='icon'
            href='/favicon-notification.ico'
          />
        </Head>
      ) : null}
    </>
  )
}

const HeadManager = memo(HeadManagerRoot)

type OneSignalContextValue = {
  initialized: boolean
  showNativePrompt: (
    params: {
      onSuccess?: () => void
      onError?: () => void
      onFinally?: () => void
    } | void
  ) => Promise<void>
  setSubscription: (isActive: boolean) => void
  isSubscribed: boolean
  notificationsUnviewedNumber: number
  hasSomeNotificationUnviewed: boolean
}

export const OneSignalContext = createContext<OneSignalContextValue>(
  {} as OneSignalContextValue
)

type OneSignalProviderProps = {
  children: ReactNode
}

export const OneSignalProvider = ({ children }: OneSignalProviderProps) => {
  const [initialized, setInitialized] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false)

  const currentUser = InternalUserContext.useSelector(state => state.currentUser)

  const useNotificationsResponse = useNotifications()

  const isClientSide = useClientSide()

  const router = useRouter()

  const notificationsUnviewedNumber = useMemo(
    () =>
      isClientSide
        ? useNotificationsResponse.response?.data?.filter?.(notfic => !notfic.hasViewed)
            ?.length || 0
        : 0,
    [isClientSide, useNotificationsResponse.response?.data]
  )

  const hasSomeNotificationUnviewed = useMemo(
    () => Boolean(notificationsUnviewedNumber),
    [notificationsUnviewedNumber]
  )

  useEffect(() => {
    if (initialized || !NEXT_PUBLIC_ONE_SIGNAL_ENABLED) {
      return
    }

    OneSignal.init({
      appId: NEXT_PUBLIC_ONE_SIGNAL_APP_ID,
      safari_web_id: NEXT_PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID,
      notifyButton: {
        enable: false,
      },
      welcomeNotification: {
        disable: true,
      },
      autoResubscribe: true,
      serviceWorkerParam: {
        scope: '/js/push/onesignal/',
      },
      serviceWorkerPath: './js/push/onesignal/OneSignalSDKWorker.js',

      ...(process.env.NODE_ENV === 'development'
        ? {
            allowLocalhostAsSecureOrigin: true,
          }
        : {}),
    }).then(() => {
      setInitialized(true)
      // eslint-disable-next-line no-console
      console.info('[ INFO ] OneSignal initialized.')
    })
  }, [initialized])

  useEffect(() => {
    if (!currentUser || !initialized) {
      return
    }

    OneSignal.setExternalUserId(currentUser.id)
  }, [currentUser, initialized])

  const showNativePrompt = useCallback(
    async (
      params: {
        onSuccess?: () => void
        onError?: () => void
        onFinally?: () => void
      } | void
    ) => {
      try {
        await OneSignal.showNativePrompt()

        if (params) {
          params.onSuccess?.()
        }
      } catch {
        if (params) {
          params.onError?.()
        }
      } finally {
        if (params) {
          params.onFinally?.()
        }
      }
    },
    []
  )

  const setSubscription = useCallback((isActive: boolean) => {
    try {
      OneSignal.setSubscription(isActive)
    } catch {
      // empty
    }
  }, [])

  useEffect(() => {
    if (!initialized) {
      return
    }

    OneSignal.isPushNotificationsEnabled()
      .then(sub => {
        setIsSubscribed(sub)
      })
      .catch(() => {
        setIsSubscribed(false)
      })

    try {
      OneSignal.on('subscriptionChange', subscribed => {
        setIsSubscribed(subscribed)
      })
    } catch {
      setIsSubscribed(false)
    }
  }, [initialized])

  useEffect(() => {
    if (!initialized) {
      return
    }

    try {
      const notification = new Audio('/sounds/notification.mp3')

      OneSignal.on('notificationDisplay', () => {
        notification.play()
      })
    } catch {
      // empty
    }
  }, [initialized])

  useEffect(() => {
    if (!currentUser) {
      return
    }

    if (!initialized) {
      return
    }

    if (isSubscribed) {
      return
    }

    const win = getWindow()

    const key = '_nexpy-notification-modal-request-has-fired'

    const requestHasFired = win?.localStorage?.getItem?.(key) === 'true'

    if (requestHasFired) {
      return
    }

    setIsNotificationModalOpen(true)

    win?.localStorage?.setItem?.(key, 'true')
  }, [currentUser, initialized, isSubscribed])

  const value = useMemo(
    () => ({
      notificationsUnviewedNumber,
      hasSomeNotificationUnviewed,
      initialized,
      showNativePrompt,
      setSubscription,
      isSubscribed,
    }),
    [
      hasSomeNotificationUnviewed,
      initialized,
      isSubscribed,
      notificationsUnviewedNumber,
      setSubscription,
      showNativePrompt,
    ]
  )

  const currentNotificationTitle = useMemo(
    () =>
      `${notificationsUnviewedNumber ? `(${notificationsUnviewedNumber}) ` : ''}Nexpy`,
    [notificationsUnviewedNumber]
  )

  return (
    <OneSignalContext.Provider value={value}>
      {children}

      <Modal
        externalIsOpen={isNotificationModalOpen}
        onClickOutside={() => {
          setIsNotificationModalOpen(false)
        }}
        maxHeight='90vh'
        overflowY='auto'
        customAnimation={{
          in: {
            opacity: 1,
            scale: 1,
          },
          initial: {
            opacity: 0,
            scale: 1.1,
          },
          transition: {
            duration: 0.1,
          },
        }}
        w='60rem !important'
        render={() => (
          <Flex
            flexDirection='column'
            gap='2.4rem'
            alignItems='center'
            justifyContent='center'
          >
            <Notify w='16rem' />

            <Text textAlign='center' maxWidth='35rem'>
              Ative as notificações
            </Text>
            <Text
              color='oldSilver'
              textAlign='center'
              mt='1.2rem'
              mb='4rem'
              maxWidth='42rem'
            >
              Com as notificações ativadas o app te avisa em agendamentos próximos ou
              informações importantes.
            </Text>

            <Button
              onClick={() => {
                setIsNotificationModalOpen(false)

                router.push(AccountManageRoutes.NOTIFICATIONS)
              }}
              textTransform='none'
            >
              Gerenciar notificações
            </Button>
          </Flex>
        )}
      >
        {() => null}
      </Modal>

      <HeadManager
        currentNotificationTitle={currentNotificationTitle}
        hasSomeNotificationUnviewed={hasSomeNotificationUnviewed}
      />
    </OneSignalContext.Provider>
  )
}
