/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/naming-convention */

export const { NODE_ENV } = process.env

export const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL

export const NEXT_PUBLIC_DEVELOPMENT_FEATURES =
  process.env.NEXT_PUBLIC_DEVELOPMENT_FEATURES === 'true'

export const NEXT_PUBLIC_COMPANY_CREATION_ACTIVE =
  process.env.NEXT_PUBLIC_COMPANY_CREATION_ACTIVE === 'true'

export const NEXT_PUBLIC_IS_HOTJAR_ACTIVE =
  process.env.NEXT_PUBLIC_IS_HOTJAR_ACTIVE === 'true'

export const NEXT_PUBLIC_ONE_SIGNAL_APP_ID = process.env
  .NEXT_PUBLIC_ONE_SIGNAL_APP_ID as string

export const NEXT_PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID = process.env
  .NEXT_PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID as string

export const NEXT_PUBLIC_ONE_SIGNAL_ENABLED =
  process.env.NEXT_PUBLIC_ONE_SIGNAL_ENABLED === 'true'
