import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { AxiosError } from 'axios'
import { CompaniesRoutes } from 'routes/companies'
import { PwaRoutes } from 'routes/pwa'

import { service_GetCompanyByQrId } from 'services/clientSide/http/companies'

import { getLocalStoragePrefix } from 'utils/cache'
import { getWindow } from 'utils/globals'

const PwaProfileRedirectManager = () => {
  const router = useRouter()

  useEffect(() => {
    const resolveC = async () => {
      const storagePrefix = getLocalStoragePrefix('preload_app_company')

      try {
        const wind = getWindow()

        if (wind) {
          const c = wind.localStorage.getItem(storagePrefix)

          if (c) {
            const parsedC = JSON.parse(c)

            const response = await service_GetCompanyByQrId(parsedC)

            try {
              wind.localStorage.removeItem(storagePrefix)
            } catch {
              // empty
            }

            if (response?.data?.username) {
              router.replace(
                CompaniesRoutes.PROFILE({
                  username: response.data.username,
                })
              )
            }
          }
        }
      } catch (error) {
        const err = error as AxiosError

        try {
          if (err.response?.status && err.response.status >= 400) {
            const wind = getWindow()

            wind?.localStorage.removeItem(storagePrefix)
          }
        } catch {
          // empty
        }
      }
    }

    if (
      router.pathname !== PwaRoutes.PREPARE &&
      router.pathname !== PwaRoutes.INSTALL &&
      router.pathname !== PwaRoutes.IOS_INSTALL &&
      router.pathname !== PwaRoutes.RECOVER_INSTALL
    ) {
      resolveC()
    }
  }, [router])

  return null
}

export default PwaProfileRedirectManager
