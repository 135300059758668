import dayjs from 'dayjs'

import {
  DEFAULT_GLOBAL_APP_REQUEST_CACHE_NAME,
  DEFAULT_CACHE_BUFFER_INTERVAL,
} from 'constants/cache'
import { slugify } from 'utils/formatters'
import { getWindow } from 'utils/globals'

type CacheItem = [string, any]

export const getLocalStoragePrefix = (key: string) => `${slugify('Nexpy')}/${key}`

const cleanCacheReducer = (acc: CacheItem[], cacheItem: CacheItem) => {
  if (cacheItem[1]) {
    return [...acc, cacheItem]
  }

  return [...acc]
}

const isNeedStartNewCache = () => {
  const wind = getWindow()
  const storagePrefix = getLocalStoragePrefix('_internal-request-cache-datetime')

  if (!wind) {
    return true
  }

  try {
    const cacheDatetime = wind.localStorage.getItem(storagePrefix)

    const reset = () => {
      wind.localStorage.setItem(storagePrefix, dayjs().toISOString())
    }

    if (!cacheDatetime) {
      reset()

      return true
    }

    const cacheDatetimeInstance = dayjs(cacheDatetime)

    if (!cacheDatetimeInstance.isValid()) {
      reset()

      return true
    }

    if (!cacheDatetimeInstance.isSame(dayjs(), 'week')) {
      reset()

      return true
    }

    return false
  } catch {
    // empty
  }

  return true
}

export const swrCacheProvider = () => {
  const resolvedCacheNamePrefix = getLocalStoragePrefix(
    DEFAULT_GLOBAL_APP_REQUEST_CACHE_NAME
  )
  const isNeededStartNewCache = isNeedStartNewCache()

  if (isNeededStartNewCache) {
    try {
      localStorage.removeItem(resolvedCacheNamePrefix)
    } catch {
      // empty
    }
  }

  try {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const readCacheFromLocalStorage = () => {
        return JSON.parse(
          localStorage.getItem(resolvedCacheNamePrefix) || '[]'
        ) as CacheItem[]
      }

      const map = new Map(readCacheFromLocalStorage())

      const writeCache = () => {
        const anyWindow = window as any

        if (anyWindow.unsafe_disable_SWR_cache_write_in_out_of_window) {
          return
        }

        const mapForCache = new Map()

        const currentStoredCache = readCacheFromLocalStorage() as CacheItem[]
        const cleanedStoredCache = currentStoredCache.reduce(
          cleanCacheReducer,
          [] as CacheItem[]
        )

        const currentAppCache = Array.from(map.entries()) as CacheItem[]
        const cleanedAppCache = currentAppCache.reduce(
          cleanCacheReducer,
          [] as CacheItem[]
        )

        cleanedStoredCache.forEach(cacheItem => {
          mapForCache.set(cacheItem[0], cacheItem[1])
        })

        cleanedAppCache.forEach(cacheItem => {
          mapForCache.set(cacheItem[0], cacheItem[1])
        })

        try {
          localStorage.setItem(
            resolvedCacheNamePrefix,
            JSON.stringify(Array.from(mapForCache.entries()))
          )
        } catch {
          localStorage.removeItem(resolvedCacheNamePrefix)
        }
      }

      window.setInterval(writeCache, DEFAULT_CACHE_BUFFER_INTERVAL)

      return map
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return new Map()
}
