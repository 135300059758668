import { useState, Dispatch, SetStateAction, ReactNode, useMemo } from 'react'

import { createContext } from '@nexpy/react-easy-context-api'

type ConsoleSettingsMenuContextValue = {
  isOpened: boolean
  setIsOpened: Dispatch<SetStateAction<boolean>>
}

export const ConsoleSettingsMenuContext = createContext<ConsoleSettingsMenuContextValue>(
  {} as ConsoleSettingsMenuContextValue
)

type MenuProviderProps = {
  children: ReactNode
}

export const ConsoleSettingsMenuProvider = ({ children }: MenuProviderProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const value = useMemo(() => ({ isOpened, setIsOpened }), [isOpened])

  return (
    <ConsoleSettingsMenuContext.Provider value={value}>
      {children}
    </ConsoleSettingsMenuContext.Provider>
  )
}
