import { createGlobalStyle } from '@xstyled/styled-components'

import { customTheme } from './theme'

const GlobalStyle = createGlobalStyle`
  :root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: ${customTheme.colors.picton};
    --toastify-color-success: ${customTheme.colors.safe};
    --toastify-color-warning: ${customTheme.colors.warning};
    --toastify-color-error: ${customTheme.colors.danger};
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: fit-content;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --nexpy-divider: ${customTheme.colors.gainsboro};
    --nexpy-white: ${customTheme.colors.white};
  }

  html, body {
    background-color: var(--nexpy-white);
    orientation: portrait !important;
  }

  img {
    pointer-events: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
            box-shadow: 0 0 0 30px var(--nexpy-white) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--nexpy-white) inset !important;

  }

  input[type="search" i]::-webkit-search-cancel-button {
    filter: grayscale(1);
    cursor: pointer;
  }

  *::-webkit-scrollbar {
    height: 8px !important;
    width: 8px !important;
    background-color: transparent !important;
  }

  .small-scroll::-webkit-scrollbar {
    height: 4px !important;
    width: 4px !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: silverChalice !important;
    border-radius: 12px !important;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 12px !important;
    margin: 0.4rem 0 !important;
  }

  .nextLink {
    font-size: 1.4rem;
    font-weight: semibold;
    text-decoration: none;
    color: 'dodger';
  }

  .Toastify__toast--error {
    max-width: 70rem;
  }

  .Toastify__toast {
    min-width: 20rem;
  }

  .slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) both;
            animation: slide-in-left 0.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) both;
  }

  .fade-out {
    -webkit-animation: fade-out 300ms ease-out both;
            animation: fade-out 300ms ease-out both;
  }

  .react-switch-handle {
    transition: all 0.2s ease !important;
  }

  /**
  * ----------------------------------------
  * animation slide-in-left
  * ----------------------------------------
  */
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  /**
  * ----------------------------------------
  * animation fade-out
  * ----------------------------------------
  */
  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .slide-right {
    animation: slide-right 0.3s ease;
  }

  .slide-left {
    animation: slide-left 0.3s ease;
  }

  .fade-in {
    animation: fade-in 0.3s ease;
  }

  @keyframes slide-right {
    0% {
      opacity: 0.1;
      transform: translateX(-10px);
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-left {
    0% {
      opacity: 0.1;
      transform: translateX(10px);
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  .slide-right-no-op {
    animation: slide-right-no-op 0.3s ease;
  }

  .slide-left-no-op {
    animation: slide-left-no-op 0.3s ease;
  }

  @keyframes slide-right-no-op {
    0% {
      transform: translateX(-6px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-left-no-op {
    0% {
      transform: translateX(6px);
    }
    100% {
      transform: translateX(0);
    }
  }
`

export default GlobalStyle
