import { COMPANIES } from './internal/routePrefixes'

const basePaths = {
  PROFILE: '/c',
  BIO: '/bio',
}

export const CompaniesRoutes = {
  CREATE: `${COMPANIES}/create`,
  PROFILE: ({ username }: { username: string }) => `${basePaths.PROFILE}/${username}`,
  BIO: ({ username }: { username: string }) => `${basePaths.BIO}/${username}`,
  SCHEDULING: ({ companyId }: { companyId: string }) =>
    `${COMPANIES}/${companyId}/scheduling`,
}

export const _Public_CompaniesRoutes = [
  `${basePaths.PROFILE}/:username`,
  `${basePaths.BIO}/:username`,
]
