import { AxiosRequestConfig } from 'axios'
import hash from 'stable-hash'

import { AUTH_SECURE_PREFIX } from 'constants/auth'

import { FetcherSignature, FetcherSignatureResponse } from 'types/services'

type GetHttpServiceParams<Fetcher extends FetcherSignature> = {
  fetcher: Fetcher
  path: string
  args?: AxiosRequestConfig
}

export type AuthParams =
  | {
      token: string | null
    }
  | undefined

export type GetHttpServiceReturn<Return> = {
  fetcher: (params: Partial<AuthParams>) => FetcherSignatureResponse<Return>
  key: string
}

export const withAuthHeader = (
  token: string | null,
  headers?: AxiosRequestConfig['headers']
) => ({
  headers: { ...headers, Authorization: token },
})

export const getHttpService = <Return, Fetcher extends FetcherSignature = any>({
  fetcher,
  path,
  args,
}: GetHttpServiceParams<Fetcher>) => {
  if (args) {
    return {
      fetcher: (params: Partial<AuthParams>) =>
        fetcher<Return>(path, {
          ...args,
          ...withAuthHeader(params?.token || null, args.headers),
        }),
      key: `_service-${path}_args-${hash(args)}`,
    }
  }

  return {
    fetcher: (params: Partial<AuthParams>) =>
      fetcher<Return>(path, { headers: { Authorization: params?.token } }),
    key: `_service-${path}`,
  }
}

export const getApiAuthPath = (path: string) => `${AUTH_SECURE_PREFIX}${path}`

export const createAuthHttpService = <Return, Fetcher extends FetcherSignature = any>({
  fetcher,
  path,
  args,
}: GetHttpServiceParams<Fetcher>) =>
  getHttpService<Return, Fetcher>({
    fetcher,
    path: getApiAuthPath(path),
    args,
  })
