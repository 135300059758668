/* eslint-disable react/no-invalid-html-attribute */
import NextHead from 'next/head'

import { META_TAGS_IDENTIFIER_KEYS } from 'constants/meta'

const base = 'https://www.nexpy.app'

const Meta = () => (
  <NextHead>
    <title key={META_TAGS_IDENTIFIER_KEYS.TITLE}>Nexpy</title>

    <meta
      name='viewport'
      content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
    />
    <meta property='og:type' content='company' />
    <meta name='robots' content='index,follow' />
    <meta charSet='utf-8' />
    <meta name='apple-mobile-web-app-capable' content='yes' />
    <meta name='apple-mobile-web-app-status-bar-style' content='default' />
    <meta name='format-detection' content='telephone=no' />
    <meta name='mobile-web-app-capable' content='yes' />
    <meta name='author' content='Nexpy <https://www.nexpy.com.br>' />

    <link key={META_TAGS_IDENTIFIER_KEYS.FAVICON} rel='icon' href='/favicon.ico' />
    <link key={META_TAGS_IDENTIFIER_KEYS.MANIFEST} rel='manifest' href='/manifest.json' />
    <link
      key={META_TAGS_IDENTIFIER_KEYS.APPLE_TOUCH_ICON}
      rel='apple-touch-icon'
      href={`${base}/images/icons/icon-192x192.png`}
    />

    <meta
      key={META_TAGS_IDENTIFIER_KEYS.APPLICATION_NAME}
      name='application-name'
      content='Nexpy'
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.APPLE_MOBILE_WEB_APP_TITLE}
      name='apple-mobile-web-app-title'
      content='Nexpy'
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.DESCRIPTION}
      name='description'
      content='Nexpy, gerenciamento privado avançado de agendamentos. Conquistar sorrisos, simplificando.'
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.THEME_COLOR}
      name='theme-color'
      content='#732fe0'
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.OG_DESCRIPTION}
      property='og:description'
      content='Nexpy, gerenciamento privado avançado de agendamentos. Conquistar sorrisos, simplificando.'
    />
    <meta key={META_TAGS_IDENTIFIER_KEYS.OG_TITLE} property='og:title' content='Nexpy' />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.OG_IMAGE}
      property='og:image'
      content={`${base}/images/icons/icon-192x192.png`}
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.SITE_NAME}
      property='og:site_name'
      content='Nexpy'
    />
    <meta key={META_TAGS_IDENTIFIER_KEYS.OG_URL} property='og:url' content={base} />
    <meta property='fb:app_id' content='283583740783965' />

    <meta
      key={META_TAGS_IDENTIFIER_KEYS.TWITTER_TITLE}
      name='twitter:title'
      content='Nexpy'
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.TWITTER_CARD}
      name='twitter:card'
      content='summary_large_image'
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.TWITTER_DESCRIPTION}
      name='twitter:description'
      content='Nexpy, gerenciamento privado avançado de agendamentos. Conquistar sorrisos, simplificando.'
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.TWITTER_IMAGE}
      property='twitter:image'
      content={`${base}/images/icons/icon-192x192.png`}
    />
    <meta
      key={META_TAGS_IDENTIFIER_KEYS.TWITTER_URL}
      property='twitter:url'
      content={base}
    />

    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_14_Pro_Max_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_14_Pro_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_11__iPhone_XR_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/12.9__iPad_Pro_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/10.9__iPad_Air_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/10.5__iPad_Air_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/10.2__iPad_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      href={`${base}/splash_screens/8.3__iPad_Mini_landscape.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_14_Pro_Max_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_14_Pro_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_11__iPhone_XR_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/12.9__iPad_Pro_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/10.9__iPad_Air_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/10.5__iPad_Air_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/10.2__iPad_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png`}
    />
    <link
      rel='apple-touch-startup-image'
      media='screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      href={`${base}/splash_screens/8.3__iPad_Mini_portrait.png`}
    />
  </NextHead>
)

export default Meta
