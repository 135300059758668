import { ACCOUNT_MANAGE } from './internal/routePrefixes'

export const AccountManageRoutes = {
  PROFILE: `${ACCOUNT_MANAGE}/profile`,
  PERSONALIZATION: `${ACCOUNT_MANAGE}/personalization`,
  ACTIVITY: `${ACCOUNT_MANAGE}/activity`,
  NOTIFICATIONS: `${ACCOUNT_MANAGE}/notifications`,
  PRIVACY: `${ACCOUNT_MANAGE}/privacy`,
  REPORT_PROBLEM: `${ACCOUNT_MANAGE}/report-problem`,
  DEV: `${ACCOUNT_MANAGE}/dev`,
}

export const _Public_AccountManageRoutes = []
