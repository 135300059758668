import { CONSOLE } from './internal/routePrefixes'

export const ConsoleRoutes = {
  ROOT: `${CONSOLE}`,

  PROFILE: `${CONSOLE}/profile`,

  CALENDAR: `${CONSOLE}/calendar`,

  CATALOG: `${CONSOLE}/catalog`,
  CATALOG_CREATE_SERVICE: `${CONSOLE}/catalog/create`,
  CATALOG_EDIT_SERVICE({ serviceId }: { serviceId: string }) {
    return `${CONSOLE}/catalog/edit/${serviceId}`
  },

  CLIENTS: `${CONSOLE}/clients`,
  CLIENT_HISTORY({ clientId }: { clientId: string }) {
    return `${CONSOLE}/clients/history/client/${clientId}`
  },

  REPORTS: `${CONSOLE}/reports`,

  SETTINGS: `${CONSOLE}/settings`,
  SETTINGS_GENERAL: `${CONSOLE}/settings/general`,

  SETTINGS_EMPLOYEES: `${CONSOLE}/settings/employees`,
  SETTINGS_EMPLOYEES_CREATE: `${CONSOLE}/settings/employees/create`,
  SETTINGS_EMPLOYEES_EDIT({ employeeId }: { employeeId: string }) {
    return `${CONSOLE}/settings/employees/edit/${employeeId}`
  },

  SETTINGS_BUSINESS_HOURS: `${CONSOLE}/settings/business-hours`,

  SETTINGS_SUBSCRIPTION: `${CONSOLE}/settings/payments`,

  SHARE: `${CONSOLE}/share`,
}

export const _Public_ConsoleRoutes = []
