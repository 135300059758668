var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"33d340f2115b27793a8df9395914cb937b73c952"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

const ENABLE_SENTRY = !!(process.env.NODE_ENV === 'production' && SENTRY_DSN)

if (process.env.NODE_ENV === 'production' && !SENTRY_DSN) {
  console.error('Sentry is not enabled.')
}

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  enabled: ENABLE_SENTRY,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
