import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useAuth } from '@clerk/nextjs'

import { service_SetNotificationViewed } from 'services/clientSide/http/notifications'

const NotificationReadManager = () => {
  const [resolvedId, setResolvedId] = useState<string | null>(null)

  const router = useRouter()

  const notificationReadId = router.query.notificationReadId as string | undefined

  const { getToken } = useAuth()

  useEffect(() => {
    if (!notificationReadId || notificationReadId === resolvedId) {
      return
    }

    const resolve = async () => {
      setResolvedId(notificationReadId)

      try {
        const token = await getToken()

        await service_SetNotificationViewed(notificationReadId, token)
      } catch {
        // empty
      }
    }

    resolve()
  }, [getToken, notificationReadId, resolvedId, router])

  return null
}

export default NotificationReadManager
