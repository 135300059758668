import '@nexpy/design-system/dist/preflight.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'theme/react-resizable.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { ClerkProvider } from '@clerk/nextjs'
import { Box, Text } from '@nexpy/design-system'
import { NEXT_PUBLIC_IS_HOTJAR_ACTIVE } from 'environment'
import ms from 'ms'
import { ChatRoutes } from 'routes/chat'
import { MySchedulesRoutes } from 'routes/my-schedules'
import { NotificationsRoutes } from 'routes/notifications'
import { SearchRoutes } from 'routes/search'

import Meta from 'components/common/Meta'
import NotificationReadManager from 'components/common/NotificationReadManager'
import PwaProfileRedirectManager from 'components/common/PwaProfileRedirectManager'

import AppProvider from 'contexts/AppProvider'

import { Slide } from 'utils/toasts'

import { ptBR } from 'locales/clerk/pt-BR'
import ClerkStyle from 'theme/ClerkStyle'
import GlobalStyle from 'theme/GlobalStyle'

const DynamicToastContainer = dynamic(
  () => import('react-toastify').then(mod => mod.ToastContainer),
  { ssr: false }
)

const DynamicHotjarIntegration = dynamic(
  () => import('components/common/HotjarIntegration'),
  { ssr: false }
)

const App = ({ Component, pageProps }: AppProps) => {
  const [startedLazyComponents, setStartedLazyComponents] = useState(false)

  const router = useRouter()

  useEffect(() => {
    setStartedLazyComponents(true)

    const timer = setTimeout(() => {
      router.prefetch(NotificationsRoutes.ROOT)
      router.prefetch(ChatRoutes.ROOT)
      router.prefetch(SearchRoutes.ROOT)
      router.prefetch(MySchedulesRoutes.ROOT)
    }, ms('5s'))

    return () => {
      clearTimeout(timer)
    }
  }, [router])

  return (
    <ClerkProvider
      {...pageProps}
      localization={ptBR}
      appearance={{
        variables: {
          fontSize: '1.7rem',
        },
      }}
    >
      <AppProvider>
        {startedLazyComponents ? (
          <>
            <DynamicToastContainer position='bottom-left' transition={Slide} />

            {NEXT_PUBLIC_IS_HOTJAR_ACTIVE ? <DynamicHotjarIntegration /> : null}
          </>
        ) : null}

        <Meta />

        <GlobalStyle />
        <ClerkStyle />

        <PwaProfileRedirectManager />
        <NotificationReadManager />

        <Box
          position='fixed'
          top='0'
          bottom='0'
          left='0'
          right='0'
          bg='white'
          zIndex='9999'
          p='4.6rem'
        >
          <Text variant='heading'>O App Nexpy não está mais entre nós...</Text>
          <Text mt='2.4rem' variant='caption'>
            Em 06 de setembro de 2024, o App Nexpy foi descontinuado.
          </Text>

          <Text mt='1.2rem' variant='caption' maxWidth='70rem'>
            Se você possuia uma conta Nexpy, os seus dados foram excluídos
            permanentemente. Agradecemos nossos usuários, amigos e familiares que tornaram
            esse projeto possível.
          </Text>

          <Text mt='1.2rem' variant='caption' maxWidth='70rem'>
            Descanse em paz, ursinho.
          </Text>
        </Box>

        <Component {...pageProps} />
      </AppProvider>
    </ClerkProvider>
  )
}
export default App
