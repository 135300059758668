import type { LocalizationResource } from '@clerk/types'

const authMessage = 'Faça login ou cadastre-se para continuar'

const commonTexts = {
  signIn: {
    phoneCode: {
      title: 'Verifique seu telefone',
      subtitle: authMessage, // TODO: ver esse "na"
      formTitle: 'Código de verificação',
      formSubtitle:
        'Digite o código de verificação enviado para o seu número de telefone',
      resendButton: 'Reenviar código',
    },
  },
} as const

export const ptBR: LocalizationResource = {
  locale: 'pt-BR',
  socialButtonsBlockButton: 'Continuar com {{provider|titleize}}',
  dividerText: 'ou',
  formFieldLabel__emailAddress: 'Endereço de email',
  formFieldLabel__emailAddresses: 'Endereços de email',
  formFieldLabel__phoneNumber: 'Número de telefone',
  formFieldLabel__username: 'Nome de usuário',
  formFieldLabel__emailAddress_phoneNumber: 'Endereço de email ou número de telefone',
  formFieldLabel__emailAddress_username: 'Endereço de email ou nome de usuário',
  formFieldLabel__phoneNumber_username: 'Número de telefone ou nome de usuário',
  formFieldLabel__emailAddress_phoneNumber_username:
    'Endereço de email, número de telefone ou nome de usuário',
  formFieldLabel__password: 'Senha',
  formFieldLabel__currentPassword: 'Senha atual',
  formFieldLabel__newPassword: 'Nova senha',
  formFieldLabel__confirmPassword: 'Confirmar senha',
  formFieldLabel__signOutOfOtherSessions:
    'Encerrar sessão em todos os outros dispositivos',
  formFieldLabel__firstName: 'Nome',
  formFieldLabel__lastName: 'Sobrenome',
  formFieldLabel__backupCode: 'Código de backup',
  formFieldLabel__organizationName: 'Nome da organização',
  formFieldLabel__organizationSlug: 'URL do slug',
  formFieldLabel__role: 'Função',
  formFieldInputPlaceholder__emailAddress: '',
  formFieldInputPlaceholder__emailAddresses:
    'Digite ou cole um ou mais endereços de email, separados por espaços ou vírgulas',
  formFieldInputPlaceholder__phoneNumber: '',
  formFieldInputPlaceholder__username: '',
  formFieldInputPlaceholder__emailAddress_phoneNumber: '',
  formFieldInputPlaceholder__emailAddress_username: '',
  formFieldInputPlaceholder__phoneNumber_username: '',
  formFieldInputPlaceholder__emailAddress_phoneNumber_username: '',
  formFieldInputPlaceholder__password: '',
  formFieldInputPlaceholder__firstName: '',
  formFieldInputPlaceholder__lastName: '',
  formFieldInputPlaceholder__backupCode: '',
  formFieldInputPlaceholder__organizationName: '',
  formFieldInputPlaceholder__organizationSlug: '',
  formFieldError__notMatchingPasswords: `As senhas não coincidem.`,
  formFieldError__matchingPasswords: 'As senhas coincidem.',
  formFieldAction__forgotPassword: 'Esqueceu a senha?',
  formFieldHintText__optional: 'Opcional',
  formButtonPrimary: 'Continuar',
  signInEnterPasswordTitle: 'Digite sua senha',
  backButton: 'Voltar',
  footerActionLink__useAnotherMethod: 'Usar outro método',
  badge__primary: 'Primário',
  badge__thisDevice: 'Este dispositivo',
  badge__userDevice: 'Dispositivo do usuário',
  badge__otherImpersonatorDevice: 'Outro dispositivo de imitação',
  badge__default: 'Padrão',
  badge__unverified: 'Não verificado',
  badge__requiresAction: 'Requer ação',
  badge__you: 'Você',
  footerPageLink__help: 'Ajuda',
  footerPageLink__privacy: 'Privacidade',
  footerPageLink__terms: 'Termos',
  paginationButton__previous: 'Anterior',
  paginationButton__next: 'Próximo',
  paginationRowText__displaying: 'Exibindo',
  paginationRowText__of: 'de',
  membershipRole__admin: 'Administrador',
  membershipRole__basicMember: 'Membro',
  membershipRole__guestMember: 'Convidado',
  signUp: {
    start: {
      title: 'Crie sua conta',
      subtitle: authMessage, // TODO: ver esse "na" também
      actionText: 'Já tem uma conta?',
      actionLink: 'Entrar',
    },
    emailLink: {
      title: 'Verifique seu email',
      subtitle: authMessage, // TODO: ver esse também
      formTitle: 'Link de verificação',
      formSubtitle: 'Use o link de verificação enviado para o seu endereço de email',
      resendButton: 'Reenviar link',
      verified: {
        title: 'Cadastro realizado com sucesso',
      },
      loading: {
        title: 'Realizando o cadastro...',
      },
      verifiedSwitchTab: {
        title: 'Email verificado com sucesso',
        subtitle: 'Volte para a nova aba aberta para continuar',
        subtitleNewTab: 'Volte para a aba anterior para continuar',
      },
    },
    emailCode: {
      title: 'Verifique seu email',
      subtitle: authMessage, // TODO: esse também
      formTitle: 'Código de verificação',
      formSubtitle: 'Digite o código de verificação enviado para o seu endereço de email',
      resendButton: 'Reenviar código',
    },
    phoneCode: {
      title: 'Verifique seu telefone',
      subtitle: authMessage, // TODO: BAH
      formTitle: 'Código de verificação',
      formSubtitle:
        'Digite o código de verificação enviado para o seu número de telefone',
      resendButton: 'Reenviar código',
    },
    continue: {
      title: 'Preencha os campos que faltam',
      subtitle: authMessage, // TODO: eu não aguento, eu não aguento, eu não aguento
      actionText: 'Já tem uma conta?',
      actionLink: 'Entrar',
    },
  },
  signIn: {
    start: {
      title: 'Entrar',
      subtitle: authMessage, // TODO: -_-
      actionText: 'Não tem uma conta?',
      actionLink: 'Cadastrar-se',
      actionLink__use_email: 'Usar email',
      actionLink__use_phone: 'Usar telefone',
      actionLink__use_username: 'Usar nome de usuário',
      actionLink__use_email_username: 'Usar email ou nome de usuário',
    },
    password: {
      title: 'Digite sua senha',
      subtitle: authMessage, // TODO: PUTZ
      actionLink: 'Usar outro método',
    },
    forgotPasswordAlternativeMethods: {
      title: 'Esqueceu a senha?',
      label__alternativeMethods: 'Ou, entrar com outro método.',
      blockButton__resetPassword: 'Redefinir sua senha',
    },
    forgotPassword: {
      title_email: 'Verifique seu email',
      title_phone: 'Verifique seu telefone',
      subtitle: 'para redefinir sua senha',
      formTitle: 'Código de redefinição de senha',
      formSubtitle_email: 'Digite o código enviado para o seu endereço de email',
      formSubtitle_phone: 'Digite o código enviado para o seu número de telefone',
      resendButton: 'Reenviar código',
    },
    resetPassword: {
      title: 'Redefinir Senha',
      formButtonPrimary: 'Redefinir Senha',
      successMessage:
        'Sua senha foi redefinida com sucesso. Entrando, por favor aguarde um momento.',
    },
    resetPasswordMfa: {
      detailsLabel: 'Precisamos verificar sua identidade antes de redefinir sua senha.',
    },
    emailCode: {
      title: 'Verifique seu email',
      subtitle: authMessage, // TODO: ele dnv
      formTitle: 'Código de verificação',
      formSubtitle: 'Digite o código de verificação enviado para o seu endereço de email',
      resendButton: 'Reenviar código',
    },
    emailLink: {
      title: 'Verifique seu email',
      subtitle: authMessage, // TODO: dnv
      formTitle: 'Link de verificação',
      formSubtitle: 'Use o link de verificação enviado para o seu email',
      resendButton: 'Reenviar link',
      unusedTab: {
        title: 'Você pode fechar esta aba',
      },
      verified: {
        title: 'Login realizado com sucesso',
        subtitle: 'Você será redirecionado em breve',
      },
      verifiedSwitchTab: {
        subtitle: 'Volte para a aba original para continuar',
        titleNewTab: 'Login realizado em outra aba',
        subtitleNewTab: 'Volte para a nova aba aberta para continuar',
      },
      loading: {
        title: 'Fazendo login...',
        subtitle: 'Você será redirecionado em breve',
      },
      failed: {
        title: 'Este link de verificação é inválido',
        subtitle: 'Volte para a aba original para continuar.',
      },
      expired: {
        title: 'Este link de verificação expirou',
        subtitle: 'Volte para a aba original para continuar.',
      },
    },
    phoneCode: { ...commonTexts.signIn.phoneCode },
    phoneCodeMfa: { ...commonTexts.signIn.phoneCode, subtitle: '' },
    totpMfa: {
      title: 'Verificação em duas etapas',
      subtitle: '',
      formTitle: 'Código de verificação',
      formSubtitle:
        'Digite o código de verificação gerado pelo seu aplicativo autenticador',
    },
    backupCodeMfa: {
      title: 'Digite um código de backup',
      subtitle: authMessage, // TODO: psé
      formTitle: '',
      formSubtitle: '',
    },
    alternativeMethods: {
      title: 'Usar outro método',
      actionLink: 'Obter ajuda',
      blockButton__emailLink: 'Enviar link por email para {{identifier}}',
      blockButton__emailCode: 'Enviar código por email para {{identifier}}',
      blockButton__phoneCode: 'Enviar código SMS para {{identifier}}',
      blockButton__password: 'Entrar com sua senha',
      blockButton__totp: 'Usar seu aplicativo autenticador',
      blockButton__backupCode: 'Usar um código de backup',
      getHelp: {
        title: 'Obter ajuda',
        content: `Se você está tendo dificuldades para acessar sua conta, nos envie um email e trabalharemos com você para restaurar o acesso o mais rápido possível.`,
        blockButton__emailSupport: 'Enviar email para suporte',
      },
    },
    noAvailableMethods: {
      title: 'Não é possível fazer login',
      subtitle: 'Ocorreu um erro',
      message:
        'Não é possível prosseguir com o login. Não há fator de autenticação disponível.',
    },
  },
  userProfile: {
    mobileButton__menu: 'Menu',
    formButtonPrimary__continue: 'Continuar',
    formButtonPrimary__finish: 'Finalizar',
    formButtonReset: 'Cancelar',
    start: {
      headerTitle__account: 'Conta',
      headerTitle__security: 'Segurança',
      headerSubtitle__account: 'Gerencie as informações da sua conta',
      headerSubtitle__security: 'Gerencie suas preferências de segurança',
      profileSection: {
        title: 'Perfil',
      },
      usernameSection: {
        title: 'Nome de usuário',
        primaryButton__changeUsername: 'Alterar nome de usuário',
        primaryButton__setUsername: 'Definir nome de usuário',
      },
      emailAddressesSection: {
        title: 'Endereços de e-mail',
        primaryButton: 'Adicionar um endereço de e-mail',
        detailsTitle__primary: 'Endereço de e-mail principal',
        detailsSubtitle__primary: 'Este é o endereço de e-mail principal',
        detailsAction__primary: 'Concluir verificação',
        detailsTitle__nonPrimary: 'Definir como endereço de e-mail principal',
        detailsSubtitle__nonPrimary:
          'Defina este endereço de e-mail como o principal para receber comunicações relacionadas à sua conta.',
        detailsAction__nonPrimary: 'Definir como principal',
        detailsTitle__unverified: 'Endereço de e-mail não verificado',
        detailsSubtitle__unverified:
          'Este endereço de e-mail não foi verificado e pode ter funcionalidade limitada',
        detailsAction__unverified: 'Concluir verificação',
        destructiveActionTitle: 'Remover',
        destructiveActionSubtitle:
          'Excluir este endereço de e-mail e removê-lo da sua conta',
        destructiveAction: 'Remover endereço de e-mail',
      },
      phoneNumbersSection: {
        title: 'Números de telefone',
        primaryButton: 'Adicionar um número de telefone',
        detailsTitle__primary: 'Número de telefone principal',
        detailsSubtitle__primary: 'Este é o número de telefone principal',
        detailsAction__primary: 'Concluir verificação',
        detailsTitle__nonPrimary: 'Definir como número de telefone principal',
        detailsSubtitle__nonPrimary:
          'Defina este número de telefone como o principal para receber comunicações relacionadas à sua conta.',
        detailsAction__nonPrimary: 'Definir como principal',
        detailsTitle__unverified: 'Número de telefone não verificado',
        detailsSubtitle__unverified:
          'Este número de telefone não foi verificado e pode ter funcionalidade limitada',
        detailsAction__unverified: 'Concluir verificação',
        destructiveActionTitle: 'Remover',
        destructiveActionSubtitle:
          'Excluir este número de telefone e removê-lo da sua conta',
        destructiveAction: 'Remover número de telefone',
      },
      connectedAccountsSection: {
        title: 'Contas conectadas',
        primaryButton: 'Conectar conta',
        title__conectionFailed: 'Tentar conexão novamente',
        title__connectionFailed: 'Tentar conexão novamente',
        title__reauthorize: 'Reautenticação necessária',
        subtitle__reauthorize:
          'As permissões necessárias foram atualizadas e você pode estar com as funcionalidades limitadas. Por favor, reautentique este aplicativo para evitar problemas',
        actionLabel__conectionFailed: 'Tentar novamente',
        actionLabel__connectionFailed: 'Tentar novamente',
        actionLabel__reauthorize: 'Autorizar agora',
        destructiveActionTitle: 'Remover',
        destructiveActionSubtitle: 'Remover esta conta conectada da sua conta',
        destructiveActionAccordionSubtitle: 'Remover conta conectada',
      },
      passwordSection: {
        title: 'Senha',
        primaryButton__changePassword: 'Redefinir senha',
        primaryButton__setPassword: 'Definir senha',
      },
      mfaSection: {
        title: 'Verificação em duas etapas',
        primaryButton: 'Adicionar verificação em duas etapas',
        phoneCode: {
          destructiveActionTitle: 'Remover',
          destructiveActionSubtitle:
            'Remover este número de telefone dos métodos de verificação em duas etapas',
          destructiveActionLabel: 'Remover número de telefone',
          title__default: 'Fator padrão',
          title__setDefault: 'Definir como fator padrão',
          subtitle__default:
            'Este fator será usado como o método padrão de verificação em duas etapas ao fazer login.',
          subtitle__setDefault:
            'Defina este fator como o fator padrão para usá-lo como o método padrão de verificação em duas etapas ao fazer login.',
          actionLabel__setDefault: 'Definir como padrão',
        },
        backupCodes: {
          headerTitle: 'Códigos de backup',
          title__regenerate: 'Gerar novos códigos de backup',
          subtitle__regenerate:
            'Obtenha um novo conjunto de códigos de backup seguros. Os códigos de backup anteriores serão excluídos e não poderão ser utilizados.',
          actionLabel__regenerate: 'Gerar novos códigos',
        },
        totp: {
          headerTitle: 'Aplicativo autenticador',
          title: 'Fator padrão',
          subtitle:
            'Este fator será usado como o método padrão de verificação em duas etapas ao fazer login.',
          destructiveActionTitle: 'Remover',
          destructiveActionSubtitle:
            'Remover aplicativo autenticador dos métodos de verificação em duas etapas',
          destructiveActionLabel: 'Remover aplicativo autenticador',
        },
      },
      activeDevicesSection: {
        title: 'Dispositivos ativos',
        primaryButton: 'Dispositivos ativos',
        detailsTitle: 'Dispositivo atual',
        detailsSubtitle: 'Este é o dispositivo que você está usando atualmente',
        destructiveActionTitle: 'Sair',
        destructiveActionSubtitle: 'Sair da sua conta neste dispositivo',
        destructiveAction: 'Sair do dispositivo',
      },
      web3WalletsSection: {
        title: 'Carteiras Web3',
        primaryButton: 'Carteiras Web3',
        destructiveActionTitle: 'Remover',
        destructiveActionSubtitle: 'Remover esta carteira Web3 da sua conta',
        destructiveAction: 'Remover carteira',
      },
    },
    profilePage: {
      title: 'Atualizar perfil',
      imageFormTitle: 'Imagem do perfil',
      imageFormSubtitle: 'Carregar imagem',
      imageFormDestructiveActionSubtitle: 'Remover imagem',
      fileDropAreaTitle: 'Arraste o arquivo até aqui ou...',
      fileDropAreaAction: 'Selecionar arquivo',
      fileDropAreaHint:
        'Faça upload de uma imagem JPG, PNG, GIF ou WEBP com tamanho inferior a 10 MB',
      successMessage: 'Seu perfil foi atualizado.',
    },
    usernamePage: {
      title: 'Atualizar nome de usuário',
      successMessage: 'Seu nome de usuário foi atualizado.',
    },
    emailAddressPage: {
      title: 'Adicionar endereço de e-mail',
      emailCode: {
        formHint:
          'Um e-mail contendo um código de verificação será enviado para este endereço de e-mail.',
        formTitle: 'Código de verificação',
        formSubtitle: 'Digite o código de verificação enviado para {{identifier}}',
        resendButton: 'Reenviar código',
        successMessage: 'O e-mail {{identifier}} foi adicionado à sua conta.',
      },
      emailLink: {
        formHint:
          'Um e-mail contendo um link de verificação será enviado para este endereço de e-mail.',
        formTitle: 'Link de verificação',
        formSubtitle:
          'Clique no link de verificação no e-mail enviado para {{identifier}}',
        resendButton: 'Reenviar link',
        successMessage: 'O e-mail {{identifier}} foi adicionado à sua conta.',
      },
      removeResource: {
        title: 'Remover endereço de e-mail',
        messageLine1: '{{identifier}} será removido desta conta.',
        messageLine2: 'Você não poderá mais fazer login usando este endereço de e-mail.',
        successMessage: '{{emailAddress}} foi removido da sua conta.',
      },
    },
    phoneNumberPage: {
      title: 'Adicionar número de telefone',
      successMessage: '{{identifier}} foi adicionado à sua conta.',
      infoText:
        'Um SMS contendo um link de verificação será enviado para este número de telefone.',
      infoText__secondary: 'Podem ser aplicadas taxas de mensagem e dados.',
      removeResource: {
        title: 'Remover número de telefone',
        messageLine1: '{{identifier}} será removido desta conta.',
        messageLine2: 'Você não poderá mais fazer login usando este número de telefone.',
        successMessage: '{{phoneNumber}} foi removido da sua conta.',
      },
    },
    connectedAccountPage: {
      title: 'Adicionar conta conectada',
      formHint: 'Selecione um provedor para conectar sua conta.',
      formHint__noAccounts: 'Não há mais opções de autenticação disponíveis.',
      socialButtonsBlockButton: 'Conectar conta {{provider|titleize}}',
      successMessage: 'O provedor foi adicionado à sua conta',
      removeResource: {
        title: 'Remover conta conectada',
        messageLine1: '{{identifier}} será removido desta conta.',
        messageLine2:
          'Você não poderá mais usar esta conta conectada e quaisquer recursos dependentes deixarão de funcionar.',
        successMessage: '{{connectedAccount}} foi removido da sua conta.',
      },
    },
    web3WalletPage: {
      title: 'Adicionar carteira Web3',
      subtitle__availableWallets:
        'Selecione uma carteira Web3 para conectar à sua conta.',
      subtitle__unavailableWallets: 'Não há carteiras Web3 disponíveis.',
      successMessage: 'A carteira foi adicionada à sua conta.',
      removeResource: {
        title: 'Remover carteira Web3',
        messageLine1: '{{identifier}} será removido desta conta.',
        messageLine2: 'Você não poderá mais fazer login usando esta carteira Web3.',
        successMessage: '{{web3Wallet}} foi removida da sua conta.',
      },
    },
    passwordPage: {
      title: 'Definir senha',
      changePasswordTitle: 'Alterar senha',
      successMessage: 'Sua senha foi definida.',
      changePasswordSuccessMessage: 'Sua senha foi atualizada.',
      sessionsSignedOutSuccessMessage:
        'Todos os outros dispositivos foram desconectados.',
    },
    mfaPage: {
      title: 'Adicionar verificação em duas etapas',
      formHint: 'Selecione um método para adicionar.',
    },
    mfaTOTPPage: {
      title: 'Adicionar aplicativo autenticador',
      verifyTitle: 'Código de verificação',
      verifySubtitle:
        'Digite o código de verificação gerado pelo seu aplicativo autenticador',
      successMessage:
        'A verificação em duas etapas está ativada. Ao fazer login, você precisará inserir um código de verificação deste aplicativo autenticador como etapa adicional.',
      authenticatorApp: {
        infoText__ableToScan:
          'Configure um novo método de login no seu aplicativo autenticador e escaneie o seguinte código QR para vinculá-lo à sua conta.',
        infoText__unableToScan:
          'Configure um novo método de login no seu aplicativo autenticador e insira a Chave fornecida abaixo.',
        inputLabel__unableToScan1:
          'Certifique-se de que as senhas baseadas em tempo ou de uso único estejam habilitadas e, em seguida, conclua a vinculação da sua conta.',
        inputLabel__unableToScan2:
          'Alternativamente, se o seu aplicativo autenticador suportar URIs TOTP, você também pode copiar a URI completa.',
        buttonAbleToScan__nonPrimary: 'Escaneie o código QR',
        buttonUnableToScan__nonPrimary: 'Não consegue escanear o código QR?',
      },
      removeResource: {
        title: 'Remover verificação em duas etapas',
        messageLine1:
          'Os códigos de verificação deste aplicativo autenticador não serão mais necessários ao fazer login.',
        messageLine2:
          'Sua conta pode não estar tão segura. Tem certeza de que deseja continuar?',
        successMessage:
          'A verificação em duas etapas por meio do aplicativo autenticador foi removida.',
      },
    },
    mfaPhoneCodePage: {
      title: 'Adicionar verificação por código SMS',
      primaryButton__addPhoneNumber: 'Adicionar um número de telefone',
      subtitle__availablePhoneNumbers:
        'Selecione um número de telefone para registrar na verificação em duas etapas por código SMS.',
      subtitle__unavailablePhoneNumbers:
        'Não há números de telefone disponíveis para registrar na verificação em duas etapas por código SMS.',
      successMessage:
        'A verificação em duas etapas por código SMS está ativada para este número de telefone. Ao fazer login, você precisará inserir um código de verificação enviado para este número de telefone como etapa adicional.',
      removeResource: {
        title: 'Remover verificação em duas etapas',
        messageLine1:
          '{{identifier}} não receberá mais códigos de verificação ao fazer login.',
        messageLine2:
          'Sua conta pode não estar tão segura. Tem certeza de que deseja continuar?',
        successMessage:
          'A verificação por código SMS em duas etapas foi removida para {{mfaPhoneCode}}',
      },
    },
    backupCodePage: {
      title: 'Adicionar verificação por código de backup',
      title__codelist: 'Códigos de backup',
      subtitle__codelist: 'Guarde-os com segurança e mantenha-os em segredo.',
      infoText1: 'Os códigos de backup serão ativados para esta conta.',
      infoText2:
        'Mantenha os códigos de backup em segredo e guarde-os com segurança. Você pode gerar novos códigos de backup se suspeitar que foram comprometidos.',
      successSubtitle:
        'Você pode usar um desses códigos para fazer login na sua conta, caso perca o acesso ao seu dispositivo de autenticação.',
      successMessage:
        'Os códigos de backup estão ativados. Você pode usar um desses códigos para fazer login na sua conta, caso perca o acesso ao seu dispositivo de autenticação. Cada código só pode ser usado uma vez.',
      actionLabel__copy: 'Copiar todos',
      actionLabel__copied: 'Copiado!',
      actionLabel__download: 'Baixar .txt',
      actionLabel__print: 'Imprimir',
    },
  },
  userButton: {
    action__manageAccount: 'Conta e configurações',
    action__signOut: 'Sair',
    action__signOutAll: 'Sair de todas as contas',
    action__addAccount: 'Adicionar conta',
  },
  organizationSwitcher: {
    personalWorkspace: 'Espaço de Trabalho Pessoal',
    notSelected: 'Nenhuma organização selecionada',
    action__createOrganization: 'Criar Organização',
    action__manageOrganization: 'Gerenciar Organização',
  },
  impersonationFab: {
    title: 'Logado como {{identifier}}',
    action__signOut: 'Sair',
  },
  organizationProfile: {
    start: {
      headerTitle__members: 'Membros',
      headerTitle__settings: 'Configurações',
      headerSubtitle__members: 'Visualizar e gerenciar membros da organização',
      headerSubtitle__settings: 'Gerenciar configurações da organização',
    },
    profilePage: {
      title: 'Perfil da Organização',
      subtitle: 'Gerenciar perfil da organização',
      successMessage: 'A organização foi atualizada.',
      dangerSection: {
        title: 'Perigo',
        leaveOrganization: {
          title: 'Sair da organização',
          messageLine1:
            'Tem certeza de que deseja sair desta organização? Você perderá o acesso a esta organização e suas aplicações.',
          messageLine2: 'Esta ação é permanente e irreversível.',
          successMessage: 'Você saiu da organização.',
        },
      },
    },
    invitePage: {
      title: 'Convidar membros',
      subtitle: 'Convidar novos membros para esta organização',
      successMessage: 'Convites enviados com sucesso',
      detailsTitle__inviteFailed:
        'Os convites não puderam ser enviados. Corrija os seguintes itens e tente novamente:',
      formButtonPrimary__continue: 'Enviar convites',
    },
    membersPage: {
      detailsTitle__emptyRow: 'Nenhum membro para exibir',
      action__invite: 'Convidar',
      start: {
        headerTitle__active: 'Ativos',
        headerTitle__invited: 'Convidados',
      },
      activeMembersTab: {
        tableHeader__user: 'Usuário',
        tableHeader__joined: 'Entrou',
        tableHeader__role: 'Função',
        tableHeader__actions: '',
        menuAction__remove: 'Remover membro',
      },
      invitedMembersTab: {
        tableHeader__invited: 'Convidado',
        menuAction__revoke: 'Revogar convite',
      },
    },
  },
  createOrganization: {
    title: 'Criar Organização',
    formButtonSubmit: 'Criar organização',
    subtitle: 'Definir o perfil da organização',
    invitePage: {
      formButtonReset: 'Pular',
    },
  },
  unstable__errors: {
    form_identifier_not_found: '',
    form_password_pwned:
      'Esta senha foi encontrada em um vazamento e não pode ser usada. Por favor, tente outra senha.',
    form_username_invalid_length: '',
    form_param_format_invalid: 'Formato inválido.',
    form_username_invalid_character:
      'O nome de usuário só pode incluir letras, números e _.',
    form_password_length_too_short: 'A senha é muito curta.',
    form_param_nil: '',
    form_code_incorrect: '',
    form_password_incorrect: '',
    not_allowed_access: '',
    form_identifier_exists: '',
    form_password_validation_failed: 'Senha incorreta',
    form_password_not_strong_enough: 'Sua senha não é forte o suficiente.',
    form_password_size_in_bytes_exceeded:
      'Sua senha excedeu o número máximo de bytes permitidos. Por favor, encurte-a ou remova alguns caracteres especiais.',
    passwordComplexity: {
      sentencePrefix: 'Sua senha deve conter',
      minimumLength: '{{length}} caracteres ou mais',
      maximumLength: 'menos de {{length}} caracteres',
      requireNumbers: 'um número',
      requireLowercase: 'uma letra minúscula',
      requireUppercase: 'uma letra maiúscula',
      requireSpecialCharacter: 'um caractere especial',
    },
    zxcvbn: {
      notEnough: 'Sua senha não é forte o suficiente.',
      couldBeStronger:
        'Sua senha é aceitável, mas poderia ser mais forte. Tente adicionar mais caracteres.',
      goodPassword: 'Ótimo trabalho. Esta é uma excelente senha.',
      warnings: {
        straightRow: 'Fileiras retas de teclas no seu teclado são fáceis de adivinhar.',
        keyPattern: 'Padrões curtos de teclas no teclado são fáceis de adivinhar.',
        simpleRepeat: 'Caracteres repetidos como "aaa" são fáceis de adivinhar.',
        extendedRepeat:
          'Padrões de caracteres repetidos como "abcabcabc" são fáceis de adivinhar.',
        sequences: 'Sequências comuns de caracteres como "abc" são fáceis de adivinhar.',
        recentYears: 'Anos recentes são fáceis de adivinhar.',
        dates: 'Datas são fáceis de adivinhar.',
        topTen: 'Esta é uma senha muito utilizada.',
        topHundred: 'Esta é uma senha frequentemente utilizada.',
        common: 'Esta é uma senha comumente utilizada.',
        similarToCommon: 'Esta senha é similar a uma senha comumente utilizada.',
        wordByItself: 'Palavras únicas são fáceis de adivinhar.',
        namesByThemselves: 'Nomes ou sobrenomes únicos são fáceis de adivinhar.',
        commonNames: 'Nomes e sobrenomes comuns são fáceis de adivinhar.',
        userInputs: 'Não deve haver dados pessoais ou relacionados à página.',
        pwned: 'Sua senha foi exposta em uma violação de dados na Internet.',
      },
      suggestions: {
        l33t: "Evite substituições previsíveis de letras, como '@' em vez de 'a'.",
        reverseWords: 'Evite soletrar palavras comuns ao contrário.',
        allUppercase: 'Coloque letras maiúsculas em algumas, mas não em todas as letras.',
        capitalization: 'Coloque letras maiúsculas em mais do que a primeira letra.',
        dates: 'Evite datas e anos associados a você.',
        recentYears: 'Evite anos recentes.',
        associatedYears: 'Evite anos associados a você.',
        sequences: 'Evite sequências comuns de caracteres.',
        repeated: 'Evite palavras e caracteres repetidos.',
        longerKeyboardPattern:
          'Use padrões de teclado mais longos e altere a direção da digitação várias vezes.',
        anotherWord: 'Adicione mais palavras menos comuns.',
        useWords: 'Use várias palavras, mas evite frases comuns.',
        noNeed:
          'Você pode criar senhas fortes sem usar símbolos, números ou letras maiúsculas.',
        pwned: 'Se você usa esta senha em outros lugares, você deve trocá-la.',
      },
    },
  },
  dates: {
    previous6Days:
      "Último(a) {{ date | weekday('pt-BR','long') }} às {{ date | timeString('pt-BR') }}",
    lastDay: "Ontem às {{ date | timeString('pt-BR') }}",
    sameDay: "Hoje às {{ date | timeString('pt-BR') }}",
    nextDay: "Amanhã às {{ date | timeString('pt-BR') }}",
    next6Days: "{{ date | weekday('pt-BR','long') }} às {{ date | timeString('pt-BR') }}",
    numeric: "{{ date | numeric('pt-BR') }}",
  },
} as const
