import { useRouter } from 'next/router'
import { ReactNode, useCallback, useEffect, useMemo } from 'react'

import { useAuth } from '@clerk/nextjs'
import { createContext } from '@nexpy/react-easy-context-api'
import { AxiosResponse } from 'axios'
import { useClientSide } from 'hooks/useClientSide'
import { usePersistedState } from 'hooks/usePersistedState'
import { CompanyPlusIncludes } from 'models/companies'
import { CompanyCategory } from 'models/company-category'
import { ConsoleRoutes } from 'routes/console'
import stableHash from 'stable-hash'
import { KeyedMutator } from 'swr'

import { useCurrentUserCompanies } from 'services/clientSide/hooks/useCurrentUserCompanies'

type CompanyContextValue = {
  userCompanies: CompanyPlusIncludes[] | null
  currentCompany: CompanyPlusIncludes | null
  setCurrentCompany: (companyId: string) => void
  setCurrentCompanyWithUpdate: (companyId: string) => Promise<void>
  currentCompanyPrimaryCategory: CompanyCategory | null
  mutate: KeyedMutator<AxiosResponse<CompanyPlusIncludes[], any> | undefined>
  isLoading: boolean
  schedulingImpediments: {
    hasImpediment: boolean
    messages: {
      text: string
      action?: {
        callback: () => void
        label: string
      }
    }[]
  }
}

export const CompanyContext = createContext<CompanyContextValue>(
  {} as CompanyContextValue
)

type CompanyProviderProps = {
  children: ReactNode
}

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const [currentCompany, setCurrentCompanyState] =
    usePersistedState<CompanyPlusIncludes | null>(null, 'current-console-company', true)

  const { response, isLoading, mutate } = useCurrentUserCompanies()

  const isClientSide = useClientSide()

  const router = useRouter()
  const { isSignedIn } = useAuth()

  const setCurrentCompany = useCallback(
    (companyId: string) => {
      const companyFound = response?.data.find(company => company.id === companyId)

      if (companyFound) {
        setCurrentCompanyState(companyFound)
      }
    },
    [response?.data, setCurrentCompanyState]
  )

  const setCurrentCompanyWithUpdate = useCallback(
    async (companyId: string) => {
      const currentUpdatedCompanies = await mutate()

      const companyFound = currentUpdatedCompanies?.data.find(
        company => company.id === companyId
      )

      if (companyFound) {
        setCurrentCompanyState(companyFound)
      }
    },
    [mutate, setCurrentCompanyState]
  )

  const currentCompanyPrimaryCategory = useMemo(
    () =>
      currentCompany?.CompanyCategories?.find(
        category => category.id === currentCompany.primaryCompanyCategoryId
      ) || null,
    [currentCompany?.CompanyCategories, currentCompany?.primaryCompanyCategoryId]
  )

  const schedulingImpediments = (() => {
    const isActive = Boolean(currentCompany?.isActive)

    const hasOpeningHours = Boolean(currentCompany?.CompanyOpeningHours?.length)

    const hasActiveServices = currentCompany?.CompanyServices?.some?.(
      service => service.isActive && service.onlineScheduleEnabled
    )

    const hasActiveEmployees = currentCompany?.CompanyServices?.some(service =>
      service?.Employees?.some?.(
        employee => employee.isActive && employee.onlineScheduleEnabled
      )
    )

    return {
      hasImpediment: !(
        hasOpeningHours &&
        hasActiveServices &&
        hasActiveEmployees &&
        isActive
      ),
      messages: [
        ...(!hasOpeningHours
          ? [
              {
                text: 'Não há nenhum horário de funcionamento cadastrado.',
                action: {
                  callback: () => {
                    router.push(ConsoleRoutes.SETTINGS_BUSINESS_HOURS)
                  },
                  label: 'Gerenciar expediente',
                },
              },
            ]
          : []),
        ...(!hasActiveServices
          ? [
              {
                text: 'Não há nenhum serviço ativo disponível ao cliente.',
                action: {
                  callback: () => {
                    router.push(ConsoleRoutes.CATALOG)
                  },
                  label: 'Gerenciar serviços',
                },
              },
            ]
          : []),
        ...(!hasActiveEmployees
          ? [
              {
                text: 'Não há nenhum serviço que possua funcionário ativo para executá-lo.',
                action: {
                  callback: () => {
                    router.push(ConsoleRoutes.SETTINGS_EMPLOYEES)
                  },
                  label: 'Gerenciar funcionários',
                },
              },
            ]
          : []),
        ...(!isActive
          ? [
              {
                text: 'A sua empresa não está visível na plataforma pois não está ativa no momento. Ative-a acima.',
              },
            ]
          : []),
      ],
    }
  })()

  const contextValue = useMemo(() => {
    if (isClientSide) {
      return {
        userCompanies: response?.data || null,
        currentCompany,
        currentCompanyPrimaryCategory,
        mutate,
        setCurrentCompany,
        setCurrentCompanyWithUpdate,
        isLoading,
        schedulingImpediments,
      }
    }

    return {
      userCompanies: null,
      currentCompany: null,
      currentCompanyPrimaryCategory: null,
      mutate,
      setCurrentCompany,
      setCurrentCompanyWithUpdate,
      isLoading,
      schedulingImpediments,
    }
  }, [
    isClientSide,
    mutate,
    setCurrentCompany,
    setCurrentCompanyWithUpdate,
    isLoading,
    response?.data,
    currentCompany,
    currentCompanyPrimaryCategory,
    schedulingImpediments,
  ])

  useEffect(() => {
    const resolveCurrentCompany = async () => {
      const currentUpdatedCompanies = await mutate()

      const companies = currentUpdatedCompanies?.data || []
      const firstCompany = currentUpdatedCompanies?.data?.[0]

      if (!currentCompany && firstCompany) {
        setCurrentCompanyState(firstCompany)

        return
      }

      if (
        !currentCompany &&
        !firstCompany &&
        isSignedIn &&
        router.pathname.startsWith(ConsoleRoutes.ROOT)
      ) {
        return
      }

      const companyFound = companies.find(company => company.id === currentCompany?.id)

      if (currentCompany && companyFound) {
        if (stableHash(currentCompany) !== stableHash(companyFound)) {
          setCurrentCompanyState(companyFound)

          return
        }
      }

      if (currentCompany && !companyFound) {
        if (firstCompany) {
          setCurrentCompanyState(firstCompany)
        } else {
          setCurrentCompanyState(null)
        }
      }
    }

    if (isClientSide) {
      resolveCurrentCompany()
    }
  }, [
    currentCompany,
    isClientSide,
    isSignedIn,
    mutate,
    response?.data,
    router,
    setCurrentCompanyState,
  ])

  return (
    <CompanyContext.Provider value={contextValue}>{children}</CompanyContext.Provider>
  )
}
