/* eslint-disable no-console */

import { ReactNode, useEffect, useMemo, useState } from 'react'

import { useAuth } from '@clerk/nextjs'
import { createContext } from '@nexpy/react-easy-context-api'
import { InternalUser } from 'models/internal-user'

import { service_CurrentUser } from 'services/clientSide/http/user'

type InternalUserContextValue = {
  currentUser: InternalUser | null
  isLoading: boolean
}

export const InternalUserContext = createContext<InternalUserContextValue>(
  {} as InternalUserContextValue
)

type InternalUserProviderProps = {
  children: ReactNode
}

export const InternalUserProvider = ({ children }: InternalUserProviderProps) => {
  const [currentUser, setCurrentUser] = useState<InternalUser | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { isLoaded, isSignedIn, getToken } = useAuth()

  useEffect(() => {
    const resolve = async () => {
      if (!isSignedIn) {
        setCurrentUser(null)

        return
      }

      const token = await getToken()
      const { data } = await service_CurrentUser(token)

      setCurrentUser(data)
    }

    try {
      if (isLoaded && !isLoading) {
        setIsLoading(true)

        resolve()
      }
    } catch {
      console.info('User not resolved.')
    } finally {
      setIsLoading(false)
    }
  }, [getToken, isLoaded, isLoading, isSignedIn])

  const value = useMemo(
    () => ({ isLoading: !isLoaded || isLoading, currentUser }),
    [currentUser, isLoaded, isLoading]
  )

  return (
    <InternalUserContext.Provider value={value}>{children}</InternalUserContext.Provider>
  )
}
